<template>
  <div class="filter-source" :class="{ active: editing }">
    <div v-if="editing">
      <v-popover
        ref="popover"
        popoverClass="popover-Source"
        :placement="placement"
        offset="10px"
        :handleResize="true"
      >
        <template slot="popover">
          <div class="Source">
            <b-form-group label="Source :">
              <Multiselect
                v-model="selectedSource"
                label="text"
                track-by="value"
                :showLabels="false"
                :closeOnSelect="true"
                open-direction="bottom"
                @input="changeSource(selectedSource)"
                @open="openSelect"
                :allow-empty="true"
                :multiple="true"
                :options="[
                  {
                    text: 'Aucun',
                    value: 'vide'
                  },
                  ...options
                ]"
                :searchable="true"
                :internal-search="true"
                :max-height="600"
                placeholder="Rechercher source"
                :loading="getProjectsSourcesProcessing"
              >
                <span slot="noResult">Aucun source trouvé.</span>
                <span slot="noOptions">Aucun source trouvé.</span>
              </Multiselect>
            </b-form-group>
            <b-form-group label="Commercial :">
              <Multiselect
                v-if="selectedSource && selectedSource.length"
                v-model="selectedCommercialSource"
                label="text"
                track-by="value"
                :showLabels="false"
                :closeOnSelect="true"
                open-direction="bottom"
                :allow-empty="true"
                :multiple="true"
                :options="campagneSource || []"
                :searchable="true"
                :internal-search="true"
                :max-height="600"
                placeholder="Rechercher Commercial"
                :loading="getProjectsCampagnesProcessing"
                group-values="campagne"
                group-label="source"
                :group-select="true"
              >
                <span slot="noResult">Aucun Commercial trouvé.</span>
                <span slot="noOptions">Aucun Commercial trouvé.</span>
              </Multiselect>
              <Multiselect
                v-else
                v-model="selectedCampagne"
                label="text"
                track-by="value"
                :showLabels="false"
                :closeOnSelect="true"
                open-direction="bottom"
                :allow-empty="true"
                :multiple="true"
                :options="campagne || []"
                :searchable="true"
                :internal-search="true"
                :max-height="600"
                placeholder="Rechercher Commercial"
                :loading="getProjectsCampagnesProcessing"
              >
                <span slot="noResult">Aucun Commercial trouvé.</span>
                <span slot="noOptions">Aucun Commercial trouvé.</span>
              </Multiselect>
            </b-form-group>
            <b-form-group label="Campagne :">
              <b-form-input v-model="textCampagne"></b-form-input>
            </b-form-group>
          </div>
          <div class="action">
            <b-button
              variant="outline-secondary"
              size="sm"
              @click="hundlePopoverCancel()"
            >
              Annuler
            </b-button>
            <b-button variant="success" size="sm" @click="hundlePopoverOk()">
              Enregistrer
            </b-button>
          </div>
        </template>
      </v-popover>
    </div>
    <div
      @click.prevent.stop="editing = true"
      class="icon"
      @click="ouvert"
      :class="{ iconrefactor: styleFilterHiddenCol }"
    >
      <font-awesome-icon class="filter-icon" :icon="['fas', 'filter']" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { VPopover } from 'v-tooltip'
import { mapActions, mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
Vue.component('v-popover', VPopover)

export default {
  components: {
    Multiselect
  },
  props: {
    placement: {
      type: String,
      default: 'auto'
    },
    styleFilterHiddenCol: { required: false }
  },
  data() {
    return {
      selectedSource: [],
      selectedCampagne: [],
      selectedCommercialSource: [],
      campagneSource: [],
      textCampagne: null,
      editing: false
    }
  },
  methods: {
    ...mapActions([
      'fetchProjectSource',
      'fetchProjectCampagne',
      'fetchProjectsCampagnesSources'
    ]),
    hundlePopoverCancel() {
      this.$refs['popover'].hide()
      this.selectedSource = []
      this.selectedCampagne = []
      this.selectedCommercialSource = []
      this.campagneSource = []
      this.textCampagne = null
      this.editing = false
    },
    async hundlePopoverOk() {
      let payload = []
      if (this.selectedSource && this.selectedSource.length) {
        payload.push({
          key: 'source',
          value: this.selectedSource.map(id => {
            return id.value
          }),
          operateur: '=',
          keyLabel: 'Source',
          valueLabel: this.selectedSource
            .map(label => {
              return label.text
            })
            .join(' , '),
          glue:
            this.selectedSource && this.selectedSource.length > 1
              ? 'or'
              : 'and',
          keyField: 'source'
        })
      } else {
        payload.push({
          key: 'source',
          value: null,
          operateur: '='
        })
      }
      if (
        this.selectedCommercialSource &&
        this.selectedCommercialSource.length
      ) {
        payload.push({
          key: 'comm_regie',
          value: this.selectedCommercialSource.map(value => {
            return value.value
          }),
          operateur: '=',
          keyLabel: 'Commercial',
          valueLabel: this.selectedCommercialSource
            .map(label => {
              return label.text
            })
            .join(' , '),
          glue:
            this.selectedCommercialSource &&
            this.selectedCommercialSource.length > 1
              ? 'or'
              : 'and',
          keyField: 'source_campagne'
        })
      } else {
        payload.push({
          key: 'comm_regie',
          value: null,
          operateur: '='
        })
      }
      if (this.selectedCampagne && this.selectedCampagne.length) {
        payload.push({
          key: 'comm_regie',
          value: this.selectedCampagne.map(value => {
            return value.value
          }),
          operateur: '=',
          keyLabel: 'Commercial',
          valueLabel: this.selectedCampagne
            .map(label => {
              return label.text
            })
            .join(' , '),
          glue:
            this.selectedCampagne && this.selectedCampagne.length > 1
              ? 'or'
              : 'and',
          keyField: 'comm_regie'
        })
      } else {
        payload.push({
          key: 'comm_regie',
          value: null,
          operateur: '='
        })
      }
      if (this.textCampagne) {
        payload.push({
          key: 'campaign',
          value: this.textCampagne,
          operateur: '=',
          keyLabel: 'Campagne',
          valueLabel: this.textCampagne,
          glue: 'and'
        })
      } else {
        payload.push({
          key: 'campaign',
          value: null,
          operateur: '='
        })
      }
      if (
        this.selectedSource &&
        this.selectedSource.length &&
        this.selectedCommercialSource &&
        this.selectedCommercialSource.length &&
        !this.textCampagne
      ) {
        payload = payload.filter(p => {
          return p.keyField == 'source_campagne'
        })
        payload = [
          ...payload,
          {
            key: 'source',
            value: null,
            operateur: '='
          },
          {
            key: 'campaign',
            value: null,
            operateur: '='
          }
        ]
      }
      if (
        this.selectedSource &&
        this.selectedSource.length &&
        this.selectedCommercialSource &&
        this.selectedCommercialSource.length &&
        this.textCampagne
      ) {
        payload = payload.filter(p => {
          return p.value != null
        })
        payload = [
          ...payload.map(t => {
            if (t.key == 'campaign' || t.key == 'comm_regie') {
              t.glue = 'or'
            }
            return t
          }),
          {
            key: 'source',
            value: null,
            operateur: '='
          }
        ]
      }
      if (
        this.textCampagne &&
        this.selectedCampagne &&
        this.selectedCampagne.length
      ) {
        payload = payload.filter(p => {
          return p.value != null
        })
        payload = [
          ...payload.map(t => {
            if (t.key == 'campaign' || t.key == 'comm_regie') {
              t.glue = 'or'
            }
            return t
          })
        ]
      }
      this.$emit('change', payload)
      this.hundlePopoverCancel()
    },
    ouvert() {
      this.$nextTick(() => {
        this.$refs['popover'].show()
      })
    },
    openSelect() {
      this.fetchProjectSource()
    },
    async changeSource(source) {
      if (source && source.length) {
        this.selectedCampagne = []
        this.selectedCommercialSource = []
        this.campagneSource = []
        for (let index = 0; index < source.length; index++) {
          const value = source[index]
          if (value.team_id != null) {
            await this.fetchProjectsCampagnesSources(value)
            if (this.getProjectsCampagnesSources) {
              let payloadCampagne = {
                source: value.text,
                campagne: this.getProjectsCampagnesSources[value.text]
              }
              if (this.campagneSource && this.campagneSource.length) {
                for (
                  let index = 0;
                  index < this.campagneSource.length;
                  index++
                ) {
                  const campagne = this.campagneSource[index]
                  if (campagne.source != payloadCampagne.source) {
                    this.campagneSource.push(payloadCampagne)
                  }
                }
              } else {
                this.campagneSource.push(payloadCampagne)
              }
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsSources',
      'getProjectsSourcesProcessing',
      'getProjectsCampagnesSources',
      'getProjectsCampagnes',
      'getProjectsCampagnesProcessing'
    ]),
    options: function() {
      if (this.getProjectsSources) {
        const options = this.getProjectsSources
          .map(u => {
            return {
              text: u.name,
              value: u.id,
              team_id: u.team_id
            }
          })
          .sort(function compare(a, b) {
            if (a.text < b.text) return -1
            if (a.text > b.text) return 1
            return 0
          })
        return [...options]
      }
      return []
    },
    campagne: function() {
      if (
        this.getProjectsCampagnes &&
        (!this.selectedSource || !this.selectedSource.length)
      ) {
        const campagne = this.getProjectsCampagnes
          .map(u => {
            return {
              text: u.full_name,
              value: u.id
            }
          })
          .sort(function compare(a, b) {
            if (a.text < b.text) return -1
            if (a.text > b.text) return 1
            return 0
          })
        return [{ text: 'Aucun', value: 'vide' }, ...campagne]
      }
      return []
    }
  },
  watch: {
    editing(value) {
      if (value) {
        if (
          (!this.getProjectsSources || !this.getProjectsSources.length) &&
          !this.getProjectsSourcesProcessing
        ) {
          this.fetchProjectSource()
        }
        if (
          (!this.getProjectsCampagnes || !this.getProjectsCampagnes.length) &&
          !this.getProjectsCampagnesProcessing
        ) {
          this.fetchProjectCampagne()
        }
        this.$nextTick(() => {
          this.$refs['popover'].show()
        })
      }
    }
  }
}
</script>

<style lang="scss">
.tooltip {
  &.popover-Source {
    opacity: 1;
    display: block !important;
    z-index: 2000;

    .tooltip-inner {
      background: black;
      color: white;
      border-radius: 16px;
      padding: 20px 0 0;
      text-align: left;
      max-width: unset;
      width: 300px;

      .close-tooltip {
        position: absolute;
        top: 3px;
        right: 8px;
        color: #656565;
        cursor: pointer;
        &:hover {
          color: black;
        }
      }
      .Source {
        .form-group {
          margin: 0 16px 10px;
        }
      }

      .stages {
        margin: 0 16px 10px;
        .label {
          padding-bottom: calc(0.375rem + 1px);
          margin-bottom: 0;
          font-size: inherit;
          line-height: 1.5;
          color: #656565;
        }

        .options {
          display: table;
          width: 100%;
          overflow: hidden;
          margin-bottom: 4px;
          border-radius: 4px;
          table-layout: fixed;
          .stage {
            height: 28px;
            cursor: pointer;
            display: table-cell;
            text-align: center;
            padding: 0;
            font-size: 12px;
            font-weight: 700;
            color: #fff;
            background: #08a742;
            position: relative;

            &:before {
              width: 20px;
              height: 24px;
              position: absolute;
              right: -11px;
              top: 2px;
              border-top: 3px solid #fff;
              border-right: 3px solid #fff;
              transform: scaleX(0.3) rotate(45deg);
              content: ' ';
              background: #08a742;
              cursor: pointer;
              z-index: 1;
            }

            input {
              width: auto;
              position: absolute;
              opacity: 0;
              cursor: pointer;
              border: none;
              padding: 0;
              box-shadow: none;
              margin-right: 3px;
            }

            &.active {
              background: #08a742;
            }
            &.active ~ label {
              background: #e5e5e5;
            }

            &.active ~ label:before {
              background: #e5e5e5;
            }
          }
        }
      }
      .action {
        margin: 0;
        padding: 8px 16px;
        text-align: right;
        border-top: 1px solid #e5e5e5;
        background-color: #f7f7f7;
        button {
          &:first-child {
            margin-right: 4px;
          }
        }
      }
    }

    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      border-color: black;
      z-index: 1;

      border-color: black !important;
      background: white !important;
      border-width: 1px !important;
      border-left-color: rgba(0, 0, 0, 0.2) !important;
      border-right-color: rgba(0, 0, 0, 0.2) !important;
      border-top-color: rgba(0, 0, 0, 0.2) !important;
      border-bottom-color: rgba(0, 0, 0, 0.2) !important;

      width: 10px;
      height: 10px;
      z-index: -2;
      transform: rotateZ(45deg);
    }

    &[x-placement^='top'] {
      margin-bottom: 5px;

      .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^='bottom'] {
      margin-top: 5px;

      .tooltip-arrow {
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^='right'] {
      margin-left: 69px;
      .tooltip-arrow {
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[x-placement^='left'] {
      margin-right: 5px;

      .tooltip-arrow {
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &.popover {
      $color: #f9f9f9;
      max-width: unset;
      border-radius: 0.1rem;
      .popover-inner {
        background: $color;
        color: black;
        border-radius: 5px;
        box-shadow: 0 5px 30px rgba(black, 0.1);
      }

      .popover-arrow {
        border-color: $color;
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.15s;
    }
  }
}
.filter-source {
  &.active {
    margin: -9px;
  }
  .icon {
    width: 16px;
    // border: 1px solid #d8d8d8;
    position: absolute;
    left: 75%;
    right: 0%;
    bottom: 15px;
    top: 23px;
    background-color: transparent;
    cursor: pointer;
    &:hover {
      background-color: transparent;
    }
    &.iconrefactor {
      top: 15px;
    }
    .filter-icon {
      color: #c5c5c5;
      font-size: 13px;
      &:hover {
        background-color: transparent;
        color: #5e5e5e;
      }
    }
  }
}
</style>
